import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  show: true,
  unfoldable: undefined,
}

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: initialState,
  reducers: {
    setShow: (state, action) => {
      state.show = action.payload
    },
    setUnfoldable: (state, action) => {
      state.unfoldable = action.payload
    },
  },
})

export const { setShow, setUnfoldable } = sidebarSlice.actions
export default sidebarSlice.reducer
