import { array, boolean, mixed, number, object, string } from 'yup'

export const validationMap = {
  array: array(),
  number: number(),
  object: object(),
  string: string(),
  boolean: boolean(),
  mixed: mixed(),
}

export const defaultValuesMap = {
  array: [],
  number: undefined,
  object: undefined,
  string: undefined,
  boolean: undefined,
}

export const validateGameForm = ({ locales }) => {
  if (locales.length === 0) {
    return 'Form non valido. Non ci sono traduzioni'
  }

  let thereIsDefaultTranslation = false

  let i
  for (i = 0; i < locales.length; i++) {
    const locale = locales[i]

    // check if there are multiple default locales
    if (locale.isDefault) {
      if (thereIsDefaultTranslation) {
        return 'Form non valido. Ci sono più traduzioni di default'
      } else thereIsDefaultTranslation = true
    }

    const keys = Object.keys(locale)
    // check if there is at least one property
    // if (keys.length === 0) {
    //   return false
    // }

    // check if all fields are filled
    for (let i = 0; i < keys.length; i++) {
      if (locale[keys[i]] === undefined) {
        return 'Form non valido. Uno o piú campi non sono stati compilati'
      }
    }
  }

  // check if there is at least one default locale
  if (!thereIsDefaultTranslation) {
    return "Form non valido. Non c'é una traduzione di default"
  }

  return null
}

export const validateWaypointForm = ({ locales, games }) => {
  if (locales.length === 0) {
    return 'Form non valido. Non ci sono traduzioni'
  }
  if (games.length === 0) {
    return 'Form non valido. Non ci sono giochi'
  }

  let thereIsDefaultTranslation = false

  let i
  for (i = 0; i < locales.length; i++) {
    const locale = locales[i]

    // check if there are multiple default locales
    if (locale.isDefault) {
      if (thereIsDefaultTranslation) {
        return 'Form non valido. Ci sono più traduzioni di default'
      } else thereIsDefaultTranslation = true
    }

    const keys = Object.keys(locale)
    // check if there is at least one property
    // if (keys.length === 0) {
    //   return false
    // }

    // check if all fields are filled
    for (let i = 0; i < keys.length; i++) {
      if (locale[keys[i]] === undefined) {
        return 'Form non valido. Uno o piú campi non sono stati compilati'
      }
    }
  }

  // check if there is at least one default locale
  if (!thereIsDefaultTranslation) {
    return "Form non valido. Non c'é una traduzione di default"
  }

  for (i = 0; i < games.length; i++) {
    const game = games[i]
    if (!game) {
      return 'Form non valido. Uno o piú giochi non sono stati compilati'
    }

    const gameValidationRes = validateGameForm(game)
    if (gameValidationRes) {
      return gameValidationRes
    }
  }

  return null
}

export const validateItineraryForm = ({ locales, waypoints }) => {
  if (locales.length === 0) {
    return 'Form non valido. Non ci sono traduzioni'
  }
  if (waypoints.length === 0) {
    return 'Form non valido. Non ci sono tappe'
  }

  let thereIsDefaultTranslation = false

  let i
  for (i = 0; i < locales.length; i++) {
    const locale = locales[i]

    // check if there are multiple default locales
    if (locale.isDefault) {
      if (thereIsDefaultTranslation) {
        return 'Form non valido. Ci sono più traduzioni di default'
      } else thereIsDefaultTranslation = true
    }

    const keys = Object.keys(locale)

    // check if all fields are filled
    for (let i = 0; i < keys.length; i++) {
      if (locale[keys[i]] === undefined) {
        return 'Form non valido. Uno o piú campi non sono stati compilati'
      }
    }
  }

  // check if there is at least one default locale
  if (!thereIsDefaultTranslation) {
    return "Form non valido. Non c'é una traduzione di default"
  }

  for (i = 0; i < waypoints.length; i++) {
    const waypoint = waypoints[i]
    if (!waypoint) {
      return 'Form non valido. Una o piú tappe non sono state compilate'
    }

    const waypointValidationRes = validateWaypointForm(waypoint)
    if (waypointValidationRes) {
      return waypointValidationRes
    }
  }

  return null
}
