import { CCol, CFormCheck, CRow } from '@coreui/react'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import 'simplebar/dist/simplebar.min.css'
import { DynamicForm } from './dynamicForm'

export const TranslationForm = ({
  items,
  locales,
  defaultLocale,
  isLocaleEditDisabled,
  initialValue,
  onSubmit,
  onChange,
}) => {
  const { locale: initialLocale, isDefault: initialIsDefault, ...rest } = initialValue

  const [locale, setLocale] = useState(initialValue?.locale ?? defaultLocale)
  const [formValue, setFormValue] = useState(rest ?? {})
  const [isDefault, setIsDefault] = useState(initialValue?.isDefault ?? false)

  // console.log('initialValue: ', initialValue)

  const localesOptions = locales.map((locale) => ({
    value: locale,
    label: locale,
  }))

  const dynamicFormInitialValue = () => {
    const { locale, isDefault, ...rest } = initialValue

    return rest
  }

  useEffect(() => {
    const payload = { ...formValue, locale, isDefault }

    // console.log('payload: ', payload)

    onChange(payload)
  }, [formValue, locale, isDefault])

  function submit(v) {
    const payload = { ...v, locale, isDefault }
    // console.log('payload: ', payload)
    onSubmit(payload)
  }

  return (
    <div className="translations-group-container">
      <span>Lingua</span>
      <CRow>
        <CCol xs={10}>
          <Select
            options={localesOptions || []}
            defaultValue={locale ? { value: locale, label: locale } : undefined}
            isDisabled={isLocaleEditDisabled || false}
            onChange={(v) => setLocale(v.value)}
          />
        </CCol>
        <CCol xs={2}>
          <CFormCheck
            defaultChecked={isDefault}
            label="default"
            onChange={(e) => setIsDefault(e.target.checked)}
          />
        </CCol>
      </CRow>
      <CRow>
        {onSubmit ? (
          <DynamicForm
            initialValue={dynamicFormInitialValue()}
            onSubmit={submit}
            onChange={setFormValue}
            items={items}
          />
        ) : (
          <DynamicForm
            initialValue={dynamicFormInitialValue()}
            onChange={setFormValue}
            items={items}
          />
        )}
      </CRow>
    </div>
  )
}

TranslationForm.propTypes = {
  items: PropTypes.array.isRequired,
  locales: PropTypes.array.isRequired,
  defaultLocale: PropTypes.string.isRequired,
  isLocaleEditDisabled: PropTypes.bool,
  initialValue: PropTypes.object,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func.isRequired,
}
